html.has-scroll-smooth {
  overflow: hidden;
}

html.has-scroll-dragging {
  user-select: none;
}

.has-scroll-smooth body {
  overflow: hidden;
}

.has-scroll-smooth [data-scroll-container] {
  min-height: 100vh;
}

[data-scroll-direction="horizontal"] [data-scroll-container] {
  white-space: nowrap;
  white-space: nowrap;
  height: 100vh;
  display: inline-block;
}

[data-scroll-direction="horizontal"] [data-scroll-section] {
  vertical-align: top;
  white-space: nowrap;
  height: 100%;
  display: inline-block;
}

.c-scrollbar {
  transform-origin: 100%;
  opacity: 1;
  width: 11px;
  height: 100%;
  transition: transform .3s, opacity .3s;
  display: block;
  position: absolute;
  top: 0;
  right: 0;
}

.c-scrollbar:hover {
  transform: scaleX(1.45);
}

.c-scrollbar:hover, .has-scroll-scrolling .c-scrollbar, .has-scroll-dragging .c-scrollbar {
  opacity: 1;
}

[data-scroll-direction="horizontal"] .c-scrollbar {
  width: 100%;
  height: 10px;
  top: auto;
  bottom: 0;
  transform: scaleY(1);
}

[data-scroll-direction="horizontal"] .c-scrollbar:hover {
  transform: scaleY(1.3);
}

.c-scrollbar_thumb {
  opacity: .5;
  width: 7px;
  cursor: -webkit-grab;
  cursor: grab;
  background-color: #000;
  border-radius: 10px;
  margin: 2px;
  position: absolute;
  top: 0;
  right: 0;
}

.has-scroll-dragging .c-scrollbar_thumb {
  cursor: -webkit-grabbing;
  cursor: grabbing;
}

[data-scroll-direction="horizontal"] .c-scrollbar_thumb {
  bottom: 0;
  right: auto;
}

.content {
  grid-template-columns: repeat(2, 50%);
  margin-top: 5rem;
  transition: transform .6s cubic-bezier(.65, .05, .36, 1), opacity .6s cubic-bezier(.65, .05, .36, 1);
  display: grid;
}

.content.hidden {
  opacity: 0 !important;
}

.content .text {
  transition-delay: .1s;
  overflow: hidden;
}

.content.is-inview > * > * {
  opacity: 1;
  transform: translateY(0);
}

.content > * > * {
  opacity: 0;
  transition: transform .6s cubic-bezier(.65, .05, .36, 1), opacity .6s cubic-bezier(.65, .05, .36, 1);
}

.content.is-inview > * > .paragraph-wrapper > .text.is-inview * {
  transform: translateY(0);
}

.content > * > .paragraph-wrapper > .text:nth-child(2n) > p, .content > * > .paragraph-wrapper > .text:nth-child(odd) > p {
  transition: transform .6s cubic-bezier(.65, .05, .36, 1);
  transform: translateY(30vw);
}

.content .picture-wrapper {
  width: 100%;
  height: calc(100vh - 10.313vw);
  overflow: hidden;
}

.content .picture-wrapper img {
  object-fit: contain;
  width: 100%;
  height: 100%;
}

.content .text-wrapper .title-wrapper {
  height: auto;
  padding: 2rem 0;
  width: max-content !important;
}

.content .text-wrapper .title-wrapper .content__paragraph {
  transition: all 1.5s cubic-bezier(.65, .05, .36, 1);
  transform: translateY(200px)skew(10deg) !important;
}

.content .text-wrapper .title-wrapper.is-inview .content__paragraph {
  opacity: 1;
  transform: translateY(0)skew(0deg) !important;
}

.content .text-wrapper .text {
  margin: 1rem;
  padding: 1rem 5rem;
  font-size: 1.5rem;
  font-weight: 400;
  position: relative;
}

.socials-title {
  text-align: right;
}

.content.is-inview {
  opacity: 1;
  transform: translateY(0);
}

@media only screen and (width <= 768px) {
  .content {
    grid-template-columns: 1fr !important;
  }

  .content .picture-wrapper {
    height: 70vh !important;
  }
}

@media screen and (width >= 768px) {
  .column-wrap img {
    max-width: 29vw !important;
    max-height: 400px !important;
  }

  .column-wrap:nth-child(3) {
    display: flex !important;
  }
}

.modal {
  visibility: visible;
  z-index: 10;
  background-color: #0000;
  width: 100vw;
  height: 100vh;
  position: absolute;
  left: 0;
}

.modal.hidden {
  visibility: hidden;
  z-index: -1;
}

.modal.hidden .carousel-wrap {
  opacity: 0 !important;
}

.modal .selected_root {
  z-index: 20;
  opacity: 1;
  width: 100vw;
  height: 100vh;
  position: relative;
  overflow: hidden scroll;
}

.modal .selected_root.hidden {
  overflow: hidden;
}

.modal .selected_root .carousel-wrap {
  opacity: block;
  font-size: 2rem;
  position: absolute;
}

.modal .selected_root .carousel-wrap .carousel {
  width: 100%;
  height: 100%;
  position: absolute;
  overflow: hidden;
}

.modal .selected_root .carousel-wrap .carousel .img-wrap {
  opacity: 0;
  width: 100%;
  height: 100%;
  transition: all .3s cubic-bezier(.65, .05, .36, 1);
  position: absolute;
}

.modal .selected_root .carousel-wrap .carousel .img-wrap.selected {
  opacity: 1;
}

.modal .selected_root .carousel-wrap .controls {
  color: #282828;
  z-index: 20;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
}

.modal .selected_root .carousel-wrap .controls.hidden .controls__bot {
  opacity: 0;
  transform: translate(0, 20vh);
}

.modal .selected_root .carousel-wrap .controls.hidden .controls__top {
  opacity: 0;
  transform: translate(0, -20vh);
}

.modal .selected_root .carousel-wrap .controls__bot {
  opacity: 1;
  width: 100%;
  padding: 1rem;
  transition: transform 1.5s cubic-bezier(.65, .05, .36, 1), opacity 1.2s cubic-bezier(.65, .05, .36, 1);
  position: absolute;
  top: 100%;
  overflow: hidden;
  translate: 0;
}

.modal .selected_root .carousel-wrap .controls__bot button {
  color: inherit;
  text-transform: uppercase;
  font-family: JosefineBold, sans-serif;
}

.modal .selected_root .carousel-wrap .controls__bot button[disabled] {
  color: #868585;
}

.modal .selected_root .carousel-wrap .controls__bot button svg {
  width: 1.5rem;
  height: 1.5rem;
}

.modal .selected_root .carousel-wrap .controls__top {
  opacity: 1;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0 .5rem;
  transition: all 1.5s cubic-bezier(.65, .05, .36, 1), opacity 1.2s cubic-bezier(.65, .05, .36, 1);
  display: flex;
  position: absolute;
  bottom: 100%;
  translate: 0;
}

.modal .selected_root .carousel-wrap .controls__left {
  width: 10vw;
  height: 100%;
  margin-left: 10px;
  position: absolute;
  left: 100%;
  overflow: hidden;
}

.modal .selected_root .carousel-wrap .controls__thumbs {
  overflow: hidden;
}

.modal .selected_root .carousel-wrap .controls__thumbs .thumbs-wrapper {
  flex-wrap: nowrap;
  width: 100%;
  margin-top: .5rem;
  margin-left: 35%;
  padding: 0;
  transition: transform .25s cubic-bezier(.65, .05, .36, 1), opacity .8s cubic-bezier(.65, .05, .36, 1);
  display: flex;
}

.modal .selected_root .carousel-wrap .controls__thumbs .thumbs-wrapper .thumb-wrap {
  opacity: .5;
  background-color: #0000;
  min-width: 20%;
  padding: .5rem;
  transition: opacity .5s cubic-bezier(.65, .05, .36, 1);
  display: inline-block;
}

.modal .selected_root .carousel-wrap .controls__thumbs .thumbs-wrapper .thumb-wrap.selected {
  opacity: 1;
}

.modal .selected_root .carousel-wrap .controls__thumbs .thumbs-wrapper .thumb-wrap img.placeholder {
  object-fit: cover;
  width: 100%;
  height: 100%;
  transition: all .7s ease-out;
}

.modal .selected_root .carousel-wrap img {
  object-fit: cover;
  border-radius: 5px;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.modal button {
  cursor: pointer;
}

.project-link {
  display: none !important;
}

.project-link .title-wrapper {
  height: max-content !important;
}

.project-wrapper {
  color: inherit;
  position: relative;
}

.project-wrapper.selected {
  background-color: gray;
}

.columns {
  perspective: 1000px;
  justify-content: space-evenly;
  width: 100vw;
  display: flex;
}

.columns .column-wrap {
  height: 100vh;
  padding: 5vh 0 25vh;
  display: flex;
  position: relative;
}

.columns .column-wrap:nth-child(3) {
  display: none;
}

.columns .column-wrap.normal {
  flex-direction: column-reverse;
}

.columns .column-wrap.reverse {
  height: 100%;
}

.columns .column-wrap .column {
  opacity: 0;
  flex-direction: column;
  display: flex;
  position: relative;
}

.columns .column-wrap .column.reverse {
  flex-direction: column-reverse;
}

.columns .column-wrap .column .column-item {
  perspective: 1000px;
  width: 100%;
  transform-style: preserve-3d;
  justify-content: center;
  align-items: center;
  display: flex;
  position: relative;
  overflow: hidden;
}

.columns .column-wrap .column .column-item.v-hidden {
  visibility: hidden;
}

.columns .column-wrap .column .column-item button {
  cursor: pointer;
  width: max-content;
  height: max-content;
}

.columns .column-wrap .column .column-item button img {
  object-fit: cover;
  border-radius: 5px;
  max-width: 40vw;
  height: 100%;
  max-height: 40vh;
}

.footer {
  text-transform: uppercase;
  grid-template-rows: 1fr 1fr 1fr .3fr;
  width: 100vw;
  height: 100vh;
  margin-top: 5rem;
  padding: 8vw;
  font-size: 1.5rem;
  font-weight: bold;
  display: grid;
}

.footer > * > * {
  opacity: 0;
  transition: all .5s cubic-bezier(.65, .05, .36, 1);
  transform: translateY(50px)rotate(-10deg);
}

.footer > * > :nth-child(2n) {
  transform: translateX(50px)rotate(10deg);
}

.footer > .is-inview * {
  opacity: 1;
  transform: translateY(0%);
}

.footer .location {
  align-self: center;
}

.footer .contacts {
  flex-wrap: wrap;
  justify-content: space-between;
  align-self: center;
}

.footer .contacts .link-mail a {
  color: #530202;
  width: max-content;
  font-family: Josefin, sans-serif;
  font-weight: 400;
  text-decoration: none;
  position: relative;
}

.footer .contacts .link-mail a:hover:after {
  transform: scaleX(.4);
}

.footer .contacts .link-mail a:after {
  content: "";
  transform-origin: 0;
  background-color: #000;
  border-radius: 10px;
  width: 100%;
  height: 1px;
  transition: transform .3s cubic-bezier(.65, .05, .36, 1);
  display: inline-block;
  position: absolute;
  bottom: 0;
  left: 0;
  transform: scaleX(1);
}

.footer .contacts .link-mail-text {
  text-align: right;
}

.footer .contacts .socials a {
  color: inherit;
  justify-content: right;
  width: max-content;
  margin-left: auto;
  text-decoration: none;
}

.footer .contacts .socials a:hover:after {
  transform: scale(.8);
}

.footer .contacts .socials a:after {
  content: "";
  transform-origin: 0;
  background-color: #282828;
  border-radius: 10px;
  width: 100%;
  height: 1px;
  transition: transform .3s cubic-bezier(.65, .05, .36, 1);
  display: inline-block;
  position: absolute;
  bottom: 0;
  left: 0;
  transform: scaleX(.4);
}

.footer .contacts .socials svg {
  width: 1.5rem;
  height: 1.5rem;
  margin-top: 2px;
}

.footer .contacts .socials .link-text {
  padding-right: 1rem;
}

.footer-nav {
  align-self: center;
  width: max-content;
  font-size: 3.5rem;
}

.footer-nav:hover a, .footer-nav:hover button {
  opacity: .3;
}

.footer-nav button:hover, .footer-nav a:hover {
  opacity: 1 !important;
}

.footer-nav a, .footer-nav button {
  cursor: pointer;
  opacity: 0;
  width: max-content;
  color: inherit;
  font-family: inherit;
  font-weight: inherit;
  font-size: inherit;
  text-transform: inherit;
  background-color: #0000;
  border: none;
  margin-left: auto;
  padding: 0;
  text-decoration: none;
  position: relative;
}

.footer-nav a span:after, .footer-nav button span:after {
  content: "";
  transform-origin: bottom;
  background-color: #000;
  width: 1px;
  height: 70%;
  transition: transform .5s cubic-bezier(.65, .05, .36, 1);
  display: inline-block;
  position: absolute;
  bottom: 20%;
  right: -7.5%;
  transform: rotate(22.5deg)scale(1, 0);
}

.footer-nav a.is-inview:hover, .footer-nav button.is-inview:hover {
  opacity: 1;
}

.footer-nav a:hover span:after, .footer-nav button:hover span:after {
  transform: rotate(22.5deg)scale(1);
}

.footer .credits {
  text-align: right;
  opacity: .8;
  letter-spacing: -.8px;
  font-size: .9rem;
  font-weight: 400;
}

.footer .credits a {
  color: inherit;
  text-decoration: none;
}

.project-page-wrapper .gallery {
  grid-row-gap: 10rem;
  grid-template-columns: repeat(2, 1fr);
}

.project-page-wrapper .img-wrapper {
  text-align: center;
  width: 100%;
  overflow: hidden;
}

.project-page-wrapper .img-wrapper:nth-child(3n) {
  grid-column: 1 / span 2;
}

.project-page-wrapper .img-wrapper:nth-child(3n) img {
  width: 50%;
}

.project-page-wrapper .img-wrapper.is-inview img {
  opacity: 1;
  transform: scale(1);
}

.project-page-wrapper .img-wrapper img {
  opacity: 0;
  object-fit: contain;
  width: 75%;
  height: 100%;
  transition: all 1s cubic-bezier(.65, .05, .36, 1);
  transform: scale(1.2);
}

.roller {
  width: 100vw;
  height: 100vh;
  margin-top: 10rem;
  font-family: Saol;
  overflow: hidden;
}

.roller.is-inview .bg-shard-sides, .roller.is-inview .low-text {
  opacity: 1 !important;
  transform: translateY(0) !important;
}

.roller.is-inview .next-section__project-text {
  opacity: 1;
}

.roller .bg-shard-sides, .roller .low-text, .roller .next-section__project-text {
  opacity: 0;
}

.roller .bg-shard:first-child .bg-shard-sides {
  transform: translateY(-10vh);
}

.roller .bg-shard:nth-child(2) .bg-shard-sides {
  transform: translateY(10vh);
}

.roller .bg-shard:nth-child(3) .bg-shard-sides {
  transform: translateY(-20vh);
}

.roller .next-container {
  grid-template-rows: 90% 10%;
  height: 100%;
  margin: auto;
  font-size: 7ch;
}

.roller .next-container .next-section {
  text-transform: capitalize;
  place-self: center;
  width: 100%;
  height: 100%;
  padding: 0 2rem;
  position: relative;
}

.roller .next-container .next-section__img-wrapper {
  grid-column-gap: 1rem;
  background-position: 0 35%;
  background-repeat: no-repeat;
  background-size: cover;
  grid-template-columns: 1fr 1fr 1fr;
  align-items: center;
  display: grid;
  position: relative;
}

.roller .next-container .next-section__img-wrapper .bg-shard-sides {
  height: 100%;
  transition: all .5s cubic-bezier(.65, .05, .36, 1);
}

.roller .next-container .next-section__img-wrapper .bg-shard {
  width: 100%;
  height: 75%;
  transform-style: preserve-3d;
  perspective: 900px;
  background-position: 0 35%;
  background-repeat: no-repeat;
  background-size: cover;
  margin-top: 10vh;
}

.roller .next-container .next-section__img-wrapper .bg-shard.uncover .bg-shard__front:before {
  height: 0%;
}

.roller .next-container .next-section__img-wrapper .bg-shard__back {
  backface-visibility: hidden;
  background-color: #0000;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  transform: rotateY(-180deg);
}

.roller .next-container .next-section__img-wrapper .bg-shard__front {
  backface-visibility: hidden;
  background-position: 50%;
  background-size: cover;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  transform: rotateY(0);
}

.roller .next-container .next-section__img-wrapper .bg-shard .bg-shard__front:before {
  content: "";
  background-color: #1e1e1e;
  width: 100%;
  height: 100%;
  transition: all .4s cubic-bezier(.65, .05, .36, 1);
  display: inline;
  position: absolute;
  left: 0;
}

.roller .next-container .next-section__img-wrapper .bg-shard:first-child .bg-shard__front:before {
  transition-delay: .2s;
}

.roller .next-container .next-section__img-wrapper .bg-shard.uncovered .bg-shard__front:before {
  transform-origin: top;
  height: 0%;
  top: 0;
}

.roller .next-container .next-section__img-wrapper .bg-shard.covered .bg-shard__front:before {
  transform-origin: bottom;
  height: 100%;
  bottom: 0;
}

.roller .next-container .next-section__project-text {
  text-align: center;
  pointer-events: none;
  z-index: 10;
  transition: opacity .4s cubic-bezier(.65, .05, .36, 1);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.roller .next-container .low-text {
  text-transform: uppercase;
  justify-content: space-between;
  align-self: center;
  font-family: JosefineBold;
  font-size: 1rem;
  font-weight: 700;
}

.roller .next-container .low-text .prev, .roller .next-container .low-text .next {
  cursor: pointer;
  padding: 1rem 2rem;
}

@font-face {
  font-family: Saol;
  src: url("SaolDisplay-Regular.ae00b519.woff2") format("truetype");
  font-display: swap;
}

@font-face {
  font-family: Cormorant Light;
  src: url("CormorantGaramond-Light.fed567d4.ttf") format("truetype");
  font-display: swap;
}

@font-face {
  font-family: Cormorant Regular;
  src: url("CormorantGaramond-Medium.9206ceb5.ttf") format("truetype");
  font-display: swap;
}

@font-face {
  font-family: Josefin;
  src: url("JosefinSans.54e766fd.ttf") format("truetype");
  font-display: swap;
}

html.has-scroll-smooth {
  position: fixed;
  inset: 0;
  overflow: hidden;
}

*, :after, :before {
  box-sizing: border-box;
}

@media screen and (width <= 1600px) {
  html {
    font-size: 1.3vw;
  }
}

@media screen and (width <= 1268px) {
  html {
    font-size: 1.5vw;
  }
}

@media screen and (width <= 768px) {
  html {
    font-size: 1.8vw;
  }

  html svg.cursor {
    display: none !important;
  }
}

@media screen and (width <= 480px) {
  html {
    font-size: 2.5vw;
  }
}

body.dark {
  color: #ddd;
  background-color: #282828;
}

body.dark .nav, body.dark .work-items-wrapper, body.dark .roller {
  color: #ddd;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  user-select: none;
  background-color: #fff;
  margin: 0;
  font-family: Josefin, sans-serif;
  font-weight: 400;
  transition: background-color .4s linear;
  overflow: hidden;
  color: #282828 !important;
}

body .greeter {
  z-index: 1;
  position: absolute;
}

body .nav {
  z-index: 10;
  width: 100%;
  height: 10rem;
  padding: 1rem;
  font-weight: 700;
  transition: opacity .5s ease-in-out;
  position: absolute;
}

body .nav.hidden {
  z-index: 0;
  opacity: 0;
  transition: opacity .5s ease-in-out, z-index .5s ease-in-out;
}

body .nav a, body .nav select {
  color: inherit;
  font-family: Josefin, sans-serif;
  font-size: inherit;
  text-transform: uppercase;
  cursor: pointer;
  background: none;
  border: none;
  padding: 0;
  font-weight: 400;
  text-decoration: none;
}

body .nav a.underlined:after, body .nav select.underlined:after {
  content: "";
  text-align: center;
  transform-origin: 0;
  background-color: #282828;
  border-radius: 10px;
  width: 100%;
  height: 1px;
  transition: transform .7s cubic-bezier(.5, 0, 0, 1);
  display: inline-block;
  position: absolute;
  bottom: 0;
  left: 0;
  transform: scaleX(0);
}

body .nav .link-wrapper {
  margin: 1rem;
  position: relative;
}

body .nav .link-wrapper:hover a:after, body .nav .link-wrapper:hover select:after {
  transform: scaleX(1);
}

body .divider {
  text-transform: uppercase;
  text-align: center;
  margin: 3rem 0;
}

body .divider:hover .divider-text:after {
  transform: scaleX(.5);
}

body .divider .divider-text {
  opacity: 0;
  padding: 1rem 0;
  transition: opacity 1s;
  position: relative;
  font-size: 2ch !important;
}

body .divider.is-inview .divider-text {
  opacity: 1;
}

body .divider .divider-text:after {
  content: "";
  text-align: center;
  transform-origin: 0;
  background-color: #000;
  border-radius: 10px;
  width: 100%;
  height: 1px;
  transition: transform 1s cubic-bezier(.5, 0, 0, 1);
  display: inline-block;
  position: absolute;
  bottom: 0;
  left: 0;
  transform: scaleX(0);
}

body .divider.is-inview .divider-text:after {
  transform: scaleX(1);
}

svg {
  width: 1rem;
  height: 1rem;
}

svg.cursor {
  opacity: 0;
  pointer-events: none;
  z-index: 100;
  width: 40px;
  height: 40px;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

svg.cursor.cursor__inner {
  fill: #000;
  stroke: #000;
  stroke-width: 1px;
  opacity: .3;
}

.title_root {
  align-self: flex-end;
  display: block;
  overflow: visible;
}

.title_root.loaded {
  height: 0;
  display: none;
}

.title-wrapper {
  align-items: top;
  justify-content: center;
  align-items: center;
  width: 100vw;
  margin: 1rem auto;
  display: flex;
}

.title-wrapper .content__paragraph {
  opacity: 0;
  font-family: Saol;
  font-size: 5rem;
  overflow: hidden;
  transform: translateY(200%);
}

.title-wrapper .content__item {
  display: block;
}

.title-wrapper .paragraph-wrapper {
  overflow: hidden;
}

.title-wrapper.is-inview .content__paragraph {
  user-select: none;
}

.work-selector-wrapper {
  position: relative;
}

.work-selector-wrapper .preview-slides {
  width: 15rem;
  height: 15rem;
  position: relative;
}

.work-selector-wrapper .preview-slide {
  background-position: center;
  background-size: 20rem;
  width: 100%;
  height: 100%;
  transition: background .8s cubic-bezier(.65, .05, .36, 1);
}

.work-selector-wrapper .preview-slide.active:hover {
  background-size: 25rem;
}

.slides-wrapper {
  grid-template-columns: 1fr 1fr;
  display: grid;
}

.slides-wrapper .preview {
  place-self: center;
  height: max-content;
}

.slides-wrapper .preview .buttons .button {
  cursor: pointer;
  font-variant: all-petite-caps;
  background: none;
  border: none;
  align-items: center;
  font-family: Josefin, sans-serif;
}

.slides-wrapper .preview .buttons .text {
  margin: 0 10px;
}

.slides-wrapper .preview .project-titles {
  text-transform: capitalize;
  text-align: center;
  align-items: center;
  height: 35px;
  margin-top: 3rem;
  position: relative;
}

.slides-wrapper .preview .project-title {
  opacity: 0;
  font-weight: 400;
  position: absolute;
  top: 50px;
}

.slides-wrapper .preview .preview-slide {
  z-index: 0;
  position: absolute;
}

.slides-wrapper .preview .preview-slide.active {
  z-index: 2;
}

.slides-wrapper .preview .preview-slide.behind {
  z-index: 3;
  animation: 1s cubic-bezier(.47, 0, .74, .71) clip;
}

.slides-wrapper .slides {
  height: 65vw;
  position: relative;
}

.slides-wrapper .slides .work-title-wrapper {
  writing-mode: vertical-rl;
  text-orientation: mixed;
  font-variant: all-petite-caps;
  width: 30px;
  font-family: Josefin, sans-serif;
  transition: transform .5s, opacity .5s;
  position: absolute;
  top: 40%;
  left: -30px;
  overflow: hidden;
}

.slides-wrapper .slides .work-title-wrapper p {
  width: 30px;
  margin: 0;
  transition: transform .5s cubic-bezier(.65, .05, .36, 1);
}

.slides-wrapper .slides .work-title-wrapper.translate p {
  transform: translateX(30px);
}

.slides-wrapper .slides .slide {
  z-index: 0;
  width: 100%;
  position: absolute;
  right: 0;
}

.slides-wrapper .slides .slide.active {
  z-index: 2;
}

.slides-wrapper .slide.behind {
  z-index: 3;
  animation: 1s cubic-bezier(.86, 0, .07, 1) clip;
}

.slides-wrapper .slides img {
  width: 100%;
}

@keyframes clip {
  0% {
    clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%);
  }

  60% {
    clip-path: polygon(45% 0%, 100% 0%, 100% 100%, 55% 100%);
  }

  100% {
    clip-path: polygon(100% 0%, 100% 0%, 100% 100%, 100% 100%);
  }
}

.gallery-pictures-wrapper {
  grid-template-columns: repeat(2, 1fr);
  display: grid;
}

.gallery-pictures-wrapper .gallery-pic {
  text-align: center;
}

.gallery-pictures-wrapper .gallery-pic .img-wrapper {
  opacity: 0;
  justify-content: center;
  margin: 0;
  transition: opacity .7s cubic-bezier(.65, .05, .36, 1);
  display: inline-flex;
  position: relative;
}

.gallery-pictures-wrapper .gallery-pic .img-wrapper.is-inview {
  opacity: 1;
}

.gallery-pictures-wrapper .gallery-pic img {
  width: 40vw;
  min-width: 30vw;
  max-width: 70vw;
  height: 100%;
  min-height: auto;
  transition: transform 1s cubic-bezier(.65, .05, .36, 1);
}

.gallery-pictures-wrapper .gallery-pic .img-wrapper.is-inview img {
  transform: translateY(0)rotate(0);
}

.gallery-pictures-wrapper .gallery-pic .pic-title-wrapper {
  text-transform: uppercase;
  text-align: center;
  color: #fff;
  margin: 3rem 0;
  font-size: 2.7vw;
  position: absolute;
  bottom: 0;
  overflow: hidden;
}

.gallery-pictures-wrapper .gallery-pic .pic-title-wrapper .pic-title {
  pointer-events: none;
  transform-origin: center;
  transition: all .5s cubic-bezier(.65, .05, .36, 1);
}

.gallery-pictures-wrapper .gallery-pic .pic-title-wrapper .pic-title.hidden {
  opacity: 0;
  transform: translateY(100%)rotate(10deg);
}

.gallery-pictures-wrapper .gallery-pic .pic-title-wrapper .pic-title.animated {
  opacity: 1;
  transform: translateY(0%)rotate(0);
}

@keyframes revealImg {
  0% {
    clip-path: polygon(0% 100%, 100% 100%, 100% 100%, 0% 100%);
  }

  100% {
    clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%);
  }
}

button {
  background-color: #0000;
  border: none;
  padding: 0;
}

.grid {
  display: grid;
}

.flex {
  display: flex;
}

.d-none {
  display: none !important;
}

.opacity-100 {
  opacity: 1;
}

.opacity-50 {
  opacity: .5;
}

.opacity-0 {
  opacity: 0;
}

.center-content {
  justify-content: center;
  align-items: center;
}

.flex-column {
  flex-direction: column;
  display: flex;
}

.space-between {
  justify-content: space-between;
  display: flex;
}

.w-full {
  width: 100%;
}

.h-full {
  height: 100%;
}

.w-screen {
  width: 100vw !important;
}

.h-screen {
  height: 100vh;
}

.h-50 {
  height: 50vh;
}

.v-hidden {
  visibility: hidden;
}

a {
  cursor: pointer;
}

a.skip-link {
  position: absolute;
  top: -200px;
}

.lowercase {
  text-transform: lowercase;
}

.c-scrollbar {
  opacity: 1;
  transition: opacity .5s ease-in-out;
}

.c-scrollbar.hidden {
  opacity: 0;
}

a {
  color: inherit;
}

.saol {
  font-family: Saol, serif;
}

.jos {
  font-family: Josefin, sans-serif;
}

.cormorant {
  font-family: Cormorant Light, sans-serif;
}
/*# sourceMappingURL=index.81f4d525.css.map */
