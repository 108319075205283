$easeQuintic: cubic-bezier(0.65, 0.05, 0.36, 1);

.footer {
  display: grid;
  height: 100vh;
  width: 100vw;
  padding: 8vw;
  margin-top: 5rem;
  text-transform: uppercase;
  font-size: 1.5rem;
  font-weight: bold;
  grid-template-rows: 1fr 1fr 1fr 0.3fr;

  & > * > * {
    opacity: 0;
    transform: translateY(50px) rotate(-10deg);
    transition: 0.5s $easeQuintic;
    // overflow: hidden;
  }
  & > * > *:nth-child(2n) {
    transform: translateX(50px) rotate(10deg);
  }
  & > *.is-inview * {
    opacity: 1;
    transform: translateY(0%);
  }
  .location {
    align-self: center;
  }
  .contacts {
    justify-content: space-between;
    flex-wrap: wrap;
    align-self: center;
    .link-mail {
      a {
        text-decoration: none;
        color: #530202;
        position: relative;
        width: max-content;
        font-family: "Josefin", sans-serif;
        font-weight: 400;
        &:hover:after {
          transform: scaleX(0.4);
        }
        &:after {
          content: "";
          position: absolute;
          transform: scaleX(1);
          transform-origin: left;
          left: 0;
          bottom: 0;
          display: inline-block;
          border-radius: 10px;
          height: 1px;
          width: 100%;
          background-color: black;
          transition: transform 0.3s $easeQuintic;
        }
      }
      &-text {
        text-align: right;
      }
    }
    .socials {
      a {
        color: inherit;
        justify-content: right;
        text-decoration: none;
        width: max-content;
        margin-left: auto;
        &:hover:after {
          transform: scale(.8);
        }
        &:after {
          content: "";
          position: absolute;
          transform: scaleX(0.4);
          transform-origin: left;
          left: 0;
          bottom: 0;
          display: inline-block;
          border-radius: 10px;
          height: 1px;
          width: 100%;
          background-color: $text-color;
          transition: transform 0.3s $easeQuintic;
        }
      }
      svg {
        margin-top: 2px;
        width: 1.5rem;
        height: 1.5rem;
      }
      .link-text {
        padding-right: 1rem;
      }
    }
  }
  &-nav {
    font-size: 3.5rem;
    width: max-content;
    align-self: center;
    &:hover a,
    &:hover button {
      opacity: 0.3;
    }
    & button:hover,
    a:hover {
      opacity: 1 !important;
    }
    a,
    button {
      width: max-content;
      position: relative;
      cursor: pointer;
      margin-left: auto;
      opacity: 0;
      text-decoration: none;
      color: inherit;
      background-color: transparent;
      border: none;
      font-family: inherit;
      font-weight: inherit;
      font-size: inherit;
      text-transform: inherit;
      padding: 0;

      & span:after {
        content: "";
        position: absolute;
        right: -7.5%;
        bottom: 20%;
        transform-origin: bottom;
        display: inline-block;
        width: 1px;
        height: 70%;
        background-color: black;
        transition: transform 0.5s $easeQuintic;
        // transition-delay: .1s;
        transform: rotate(22.5deg) scale(1, 0);
      }
      &.is-inview:hover {
        opacity: 1;
      }
      &:hover span:after {
        transform: rotate(22.5deg) scale(1, 1);
      }
    }
  }
  .credits {
    text-align: right;
    opacity: 0.8;
    font-size: 0.9rem;
    font-weight: 400;
    letter-spacing: -0.8px;
    a {
      text-decoration: none;
      color: inherit;
    }
  }
}
