$text-color: #282828;
$bg-color: #d7cca1;
$bg-dark: $text-color;
$easeQuintic: cubic-bezier(0.65, 0.05, 0.36, 1);

@media screen and (min-width: 768px) {
  .column-wrap {
    img {
      // margin: 0px !important;
      max-width: 29vw !important;
      max-height: 400px !important;
    }
  }
  .column-wrap:nth-child(3) {
    display: flex !important;
  }
}


.modal {
  position: absolute;
  // top: 0px;
  left: 0px;
  width: 100vw;
  height: 100vh;
  background-color: transparent;
  visibility: visible;
  z-index: 10;
  &.hidden {
    visibility: hidden;
    z-index: -1;
    .carousel-wrap {
      opacity: 0 !important;
    }
  }
  .selected_root {
    width: 100vw;
    height: 100vh;
    z-index: 20;
    opacity: 1;
    position: relative;
    overflow-y: scroll;
    overflow-x: hidden;
    &.hidden {
      overflow: hidden;
    }
    // grid-template-columns: repeat(2, 50vw);
    .carousel-wrap {
      position: absolute;
      font-size: 2rem;
      opacity: block;
      .carousel {
        position: absolute;
        width: 100%;
        height: 100%;
        overflow: hidden;
        .img-wrap {
          position: absolute;
          width: 100%;
          height: 100%;
          transition: .3s $easeQuintic;
          opacity: 0;
          &.selected {
            // z-index: 10;
            opacity: 1;
          }

        }
      }
      .controls {
        position: absolute;
        height: 100%;
        width: 100%;
        top: 0;
        color: $text-color;
        z-index: 20;
        &.hidden {
          .controls__bot {
            opacity: 0;
            transform: translate(0, 20vh);
          }
          .controls__top {
            opacity: 0;
            transform: translate(0, -20vh);
          }

        }
        &__bot {
          position: absolute;
          width: 100%;
          top: 100%;
          padding: 1rem;
          opacity: 1;
          translate: 0 0;
          transition: transform 1.5s $easeQuintic, opacity 1.2s $easeQuintic;
          overflow: hidden;
          button {
            color: inherit;
            text-transform: uppercase;
            font-family: 'JosefineBold', sans-serif;
            &[disabled] {
              color: #868585;
            }
            svg {
              width: 1.5rem;
              height: 1.5rem;
            }
          }
        }
        &__top {
          display: flex;
          justify-content: space-between;
          align-items: center;
          bottom: 100%;
          width: 100%;
          padding: 0 .5rem;
          position: absolute;
          opacity: 1;
          translate: 0 0;
          transition: 1.5s $easeQuintic, opacity 1.2s $easeQuintic;
        }
        &__left {
          position: absolute;
          left: 100%;
          width: 10vw;
          height: 100%;
          overflow: hidden;
          margin-left: 10px;
        }

        &__thumbs {
          overflow: hidden;
          .thumbs-wrapper {
            width: 100%;
            padding: 0rem;
            margin-top: .5rem;
            margin-left: 35%;
            // translate: 0 0;
            transition: transform .25s $easeQuintic, opacity .8s $easeQuintic;
            display: flex;
            flex-wrap: nowrap;
            .thumb-wrap {
              &.selected {
                opacity: 1;
              }
              display: inline-block;
              background-color: transparent;
              opacity: .5;
              transition: opacity .5s $easeQuintic;
              min-width: 20%;
              padding: .5rem;
              img.placeholder {
                height: 100%;
                width: 100%;
                object-fit: cover;
                transition: .7s ease-out;

              }
            }
          }
        }

      }
      img {
        overflow: hidden;
        object-fit: cover;
        border-radius: 5px;
        width: 100%;
        height: 100%;
        // max-width: 80vw;
      }
    }
  }
  button {
    cursor: pointer;
  }
}



.project-link {
  display: none !important;
  .title-wrapper {
    height: max-content !important;
  }
}
.project-wrapper {
  color: inherit;
  position:relative;
  &.selected {
    background-color: gray;
  }
}
.columns {
  display: flex;
  justify-content: space-evenly;
  width: 100vw;
  perspective: 1000px;
  .column-wrap {
    display: flex;
    position: relative;
    height: 100vh;
    padding: 5vh 0 25vh;
    &:nth-child(3) {
      display: none;
    }
    &.normal {
      flex-direction: column-reverse;
    }
    &.reverse {
      height: 100%;
    }
    & .column {
      display: flex;
      flex-direction: column;
      position: relative;
      opacity: 0;
      &.reverse {
        flex-direction: column-reverse;
      }
      & .column-item {
        position: relative;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        perspective: 1000px;
        transform-style: preserve-3d;
        overflow: hidden;
        &.v-hidden {
          visibility: hidden;
        }
        button {
          width: max-content;
          height: max-content;
          cursor: pointer;
          img {
            object-fit: cover;
            height: 100%;
            border-radius: 5px;
            max-width: 40vw;
            max-height: 40vh;
          }
        }
      }
    }
  }
}
