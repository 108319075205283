$bg-color: #d7cca1;
$easeQuintic: cubic-bezier(0.65, 0.05, 0.36, 1);
$bg-dark: #1e1e1e;

.roller {
    margin-top: 10rem;
    overflow: hidden;
    width: 100vw;
    height: 100vh;
    font-family: Saol;
    &.is-inview .bg-shard-sides,
    &.is-inview .low-text {
    
        transform: translateY(0vh) !important;
        opacity: 1 !important;
    }
    &.is-inview .next-section__project-text { 
        opacity: 1 ;
    }
    & .bg-shard-sides,
    & .low-text,
    & .next-section__project-text {
        opacity: 0 ;
    }
    & .bg-shard:nth-child(1) .bg-shard-sides {
        transform: translateY(-10vh);
    }
    & .bg-shard:nth-child(2) .bg-shard-sides {
        transform: translateY(10vh);
    }
    & .bg-shard:nth-child(3) .bg-shard-sides {
        transform: translateY(-20vh);
    }
    .next-container {
        margin: auto;
        font-size: 7ch;
        height: 100%;
        grid-template-rows: 90% 10%;
        .next-section {
            align-self: center;
            justify-self: center;
            text-transform: capitalize;
            position: relative;
            padding: 0 2rem 0 2rem;
            // margin-top: 10vh;
            width: 100%;
            height: 100%;

            &__img-wrapper {
                display: grid;
                align-items: center;
                grid-template-columns: 1fr 1fr 1fr;
                grid-column-gap: 1rem;
                background-position: 0% 35%;
                background-repeat: no-repeat;
                background-size: cover;
                position: relative;
                .bg-shard-sides {
                    height: 100%;
                    transition: 0.5s $easeQuintic;
                }
                .bg-shard {
                    height: 75%;
                    width: 100%;
                    margin-top: 10vh;
                    background-position: 0% 35%;
                    background-repeat: no-repeat;
                    background-size: cover;
                    transform-style: preserve-3d;
                    perspective: 900px;
                    // transition: 0.4s ease-in-out;
                    & > * {
                        // transition: 0.4s ease-in-out;
                        // overflow: hidden;
                    }
                    &.uncover .bg-shard__front:before {
                        height: 0%;
                    }
                    &__back {
                        position: absolute;
                        top: 0;
                        left: 0;
                        background-color: transparent;
                        // border: 2px solid $bg-color;
                        transform: rotateY(-180deg);
                        height: 100%;
                        width: 100%;
                        backface-visibility: hidden;
                    }
                    &__front {
                        position: absolute;
                        background-position: 50% 50%;
                        top: 0;
                        left: 0;
                        width: 100%;
                        transform: rotateY(0deg);
                        height: 100%;
                        backface-visibility: hidden;
                        background-size: cover;
                    }
                    & .bg-shard__front:before {
                        transition: 0.4s $easeQuintic;
                        content: "";
                        display: inline;
                        position: absolute;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        background-color: $bg-dark;
                    }
                    &:nth-child(1) .bg-shard__front:before {
                        transition-delay: 0.1s;
                    }
                    &:nth-child(1) .bg-shard__front:before {
                        transition-delay: 0.3s;
                    }
                    &:nth-child(1) .bg-shard__front:before {
                        transition-delay: 0.2s;
                    }
                    &.uncovered .bg-shard__front:before {
                        top: 0;
                        transform-origin: top;
                        height: 0%;
                    }
                    &.covered .bg-shard__front:before {
                        transform-origin: bottom;
                        bottom: 0;
                        height: 100%;
                    }
                    /* &:hover .bg-shard__front {
                        transform: rotateY(180deg);
                    }
                    &:hover .bg-shard__back {
                        transform: rotateY(0deg);
                    } */
                }
            }
            &__project-text {
                text-align: center;
                position: absolute;
                top: 50%;
                left: 50%;
                transition: opacity 0.4s $easeQuintic;
                transform: translate(-50%, -50%);
                pointer-events: none;
                z-index: 10;
            }
        }
        .low-text {
            font-size: 1rem;
            font-weight: 700;
            font-family: "JosefineBold";
            // font-family: 'Montserrat', sans-serif;
            text-transform: uppercase;
            align-self: center;
            justify-content: space-between;
            & .prev, .next {
                padding: 1rem 2rem;
                cursor: pointer;
            }
        }
    }
}
