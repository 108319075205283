$easeQuintic: cubic-bezier(0.65, 0.05, 0.36, 1);

.project-page-wrapper {
  .gallery {
    grid-template-columns: repeat(2, 1fr);
    grid-row-gap: 10rem;
  }
  .img-wrapper {
    width: max-content;
    width: 100%;
    text-align: center;
    overflow: hidden;
    &:nth-child(3n) {
      grid-column: 1 / span 2;
      & img {
        width: 50%;
      }
    }
    &.is-inview img {
      opacity: 1;
      transform: scale(1);
    }
    img {
      width: 75%;
      height: 100%;
      opacity: 0;
      object-fit: contain;
      transform: scale(1.2);
      transition: 1s $easeQuintic;
    }
  }
}
